


export const completeListe = (multiples, oeuvres) => {

    var liste = []

    if ( oeuvres && oeuvres.length > 0 ) {

        multiples.forEach(multiple => {

            oeuvres.forEach(oeuvre => {

                if ( oeuvre.objectid === multiple.oeuvre_objectid ) {

                    const newMultiple = []

                    newMultiple.oeuvre_objectid = oeuvre.objectid
                    newMultiple.titre = oeuvre.titre
                    newMultiple.archive = oeuvre.archive
                    newMultiple.numero = multiple.numero
                    newMultiple.etat = multiple.etat
                    newMultiple.etat_de_conservation = multiple.etat_de_conservation
                    newMultiple.emplacement = multiple.emplacement
                    newMultiple.derniere_localisation = multiple.derniere_localisation
                    newMultiple.disponibilite = multiple.disponibilite
                    newMultiple.serie = oeuvre.serie
                    newMultiple.editer_par = oeuvre.editer_par
                    newMultiple.hauteur = oeuvre.hauteur
                    newMultiple.largeur = oeuvre.largeur
                    newMultiple.profondeur = oeuvre.profondeur

                    liste.push(newMultiple)

                }
            })
        })
    } else {

     /*   multiples.forEach(multiple => {
            const newMultiple = {}

            newMultiple.oeuvre_objectid = multiple.oeuvre_objectid
            newMultiple.titre = multiple.oeuvre_titre
            newMultiple.archive = multiple.oeuvre_archive
            newMultiple.numero = multiple.numero
            newMultiple.etat = multiple.etat
            newMultiple.etat_de_conservation = multiple.etat_de_conservation
            newMultiple.emplacement = multiple.emplacement
            newMultiple.derniere_localisation = multiple.derniere_localisation
            newMultiple.disponibilite = multiple.disponibilite

            liste.push(newMultiple)
        })*/
    }

    return liste
}


export const completeMultipleListe = (multiples) => {

    var liste = []

    multiples.forEach(multiple => {

        const newMultiple = {}

         // console.log("multiple : ", multiple)
        newMultiple.oeuvre_objectid = multiple.oeuvre_objectid
        newMultiple.titre = multiple.oeuvre_titre
        newMultiple.archive = multiple.oeuvre_archive
        newMultiple.numero = multiple.numero
        newMultiple.etat = multiple.etat
        newMultiple.etat_de_conservation = multiple.etat_de_conservation
        newMultiple.emplacement = multiple.emplacement
        newMultiple.derniere_localisation = multiple.derniere_localisation
        newMultiple.disponibilite = multiple.disponibilite
        // console.log("newMultiple : ", newMultiple)

         liste.push(newMultiple)

    })

    return liste
}




