import Query from './Query';
import ApiCall from './ApiCall';


export default async ({ data: oeuvre }) => {
    const query = new Query();

    if (oeuvre.dernier_proprietaire) {
        query.contains({
            fieldName: 'dernier_proprietaire',
            value: oeuvre.dernier_proprietaire
        })
    }
    
    if (oeuvre.multiple ) {

        if (oeuvre.multiple.numero) {
            query.contains({
                fieldName: 'numero',
                value: oeuvre.multiple.numero
            })
        }

        if (oeuvre.multiple.emplacement) {
            query.contains({
                fieldName: 'emplacement',
                value: oeuvre.multiple.emplacement
            })
        }
        
        if (oeuvre.multiple.derniere_localisation) {
            query.contains({
                fieldName: 'derniere_localisation',
                value: oeuvre.multiple.derniere_localisation
            })
        }
        
        if (oeuvre.multiple.dernier_proprietaire) {
            query.contains({
                fieldName: 'dernier_proprietaire',
                value: oeuvre.multiple.dernier_proprietaire
            })
        }
        if (oeuvre.multiple.etat) {
            query.matchTextExact({
                fieldName: 'etat',
                value: oeuvre.multiple.etat
            })
        }

        if (oeuvre.multiple.localisation) {
            query.contains({
                fieldName: 'localisations.localisation',
                value: oeuvre.multiple.localisation
            })
        }

        if (oeuvre.multiple.etat_de_conservation) {
            query.matchText({
                fieldName: 'etat_de_conservation',
                value: oeuvre.multiple.etat_de_conservation
            })
        }


        if (oeuvre.multiple.nonsigne) {
            query.matchNumber({
                fieldName: 'nonsigne',
                value: oeuvre.multiple.nonsigne
            })
        }

        if (oeuvre.multiple.nonnumerote) {
            query.matchNumber({
                fieldName: 'nonnumerote',
                value: oeuvre.multiple.nonnumerote
            })
        }

        
        if (oeuvre.multiple.multiple_remarques) {
            query.contains({
                fieldName: 'remarques',
                value: oeuvre.multiple.multiple_remarques
            })
        }

        if (oeuvre.multiple.multiple_conservation) {
            query.contains({
                fieldName: 'conservation',
                value: oeuvre.multiple.multiple_conservation
            })
        }
    }


    console.log("query multiple", query);

    return await ApiCall({ type: 'multiple', query })
}